<template>
  <div v-if="ordersActiveProductsMain" class="active-order__block main-block">
    <div class="active-order__head main-block__head">
      <div class="flex">
        <h1 class="main-block__title">Активные маршруты</h1>
        <span class="active-order__head-number default-btn">{{ ordersActiveProductsMain.length }}</span>
      </div>
      <div class="flex">
      </div>
    </div>
    <ul class="active-order__items main-block__box">
      <li
        v-for="(item, index) in ordersActiveProductsMain.data"
        :key="index"
        class="active-order__item flex"
      >
        <img class="active-order__img-block" src="@/assets/png/inblock-car.png" alt="" />
        <div class="active-order__box flex">
          <div class="active-order__id">
            <p class="active-order__name" @click="activeOrder(item.id)">
              {{ item.id ? code.slice(0, 10 - item.id.toString().length) : "" }}{{ item.id }}
            </p>
            <p class="active-order__text" @click="activeOrder(item.id)">
              <span v-for="subId in item.products" :key="subId.id">ANG-{{ subId.id }}</span>
            </p>
          </div>
          <div class="active-order__info" @click="activeOrder(item.id)">
            <div class="working__road flex m-0">
              <p class="working__road-text working__road_begin">
                {{ item.from }}
              </p>
              <div class="working__road-block">
                <div class="working__road-box" style="width: 75%" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">
                {{ item.to }}
              </p>
            </div>
            <div class="active-order__status flex-only">
              <img class="mr-1" src="@/assets/png/order-active.png" alt="" />
              <p class="active-order__text">
                Текущий статус:
                <label
                  class="active-order__text"
                  :style="`color: ${statusColor(item.status)}`"
                  for=""
                  >{{ statusChange(item.status) }}</label
                >
              </p>
            </div>
          </div>
          <div class="active-order__date-box" @click="activeOrder(item.id)">
            <div class="active-order__date-driver flex">
              <p class="active-order__data-name">Водитель:</p>
              <p class="active-order__data-text">
                {{ item.order_status ? item.order_status.user.name : "" }}
              </p>
            </div>
            <div class="active-order__date-transport flex">
              <p class="active-order__data-name">Транспорт:</p>
              <p class="active-order__data-text">
                {{ item.transport ? item.transport.transport.number : "" }}
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import statusMixin from "@/mixins/status";
export default {
  mixins: [statusMixin],
  data() {
    return {
      selected: null,
      code: "0000000000",
      options: [
        { value: null, text: "Помеченным" },
        { value: "a", text: "Помеченным" },
      ],
    };
  },
  methods: {
    dateProduct(value) {
      const date = value.split(" ")[0].split("-");
      const arrDate = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];
      for (let i = 1; i <= 12; i++) {
        if ((date[1] * 10) / 10 === i) {
          date[1] = arrDate[i - 1];
        }
      }
      return date[1] + " " + date[2];
    },
    activeOrder(value) {
      this.$router.push(`/route/${value}`);
    },
    addToFav(item) {
      item.fav = !item.fav;
      this.$api
        .post("/web/order-add-fav", {
          order_id: item.id,
          fav: item.fav ? "" : "false",
        })
        .then(() => {
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.message);
            item.fav = !item.fav;
          }
        });
    },
    addToPriority(item) {
      item.priority = item.priority === "default" ? "flash" : "default";
      this.$api
        .post("/web/order-add-priority", {
          order_id: item.id,
          priority: item.priority,
        })
        .then(() => {
          this.$toast.success(
            item.priority === "default"
              ? "Успешно убрана приоритетность"
              : "Успешно присвоена приоритетность"
          );
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.message);
            item.priority = item.priority === "default" ? "flash" : "default";
          }
        });
    },
  },
  computed: {
    ...mapState(["ordersActiveProductsMain"]),
  },
  async created() {
    await this.$emit("spinner");
    if(!this.ordersActiveProductsMain)
      await this.$store.dispatch("getOrdersActiveProductsMain");
  },
};
</script>

<style scoped>
.active-order__items.main-block__box {
  overflow-x: auto;
}
@supports (overflow-x: overlay) {
  .active-order__items.main-block__box {
    overflow-x: overlay;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
.active-order__items.main-block__box::-webkit-scrollbar {
  background-color: #00000000;
  display: block;
  height: 0.65rem;
  width: 0;
}
.active-order__items.main-block__box::-webkit-scrollbar-track {
  background-color: #00000000;
}
.active-order__items.main-block__box::-webkit-scrollbar-thumb {
  background-color: #bbbbbb66;
  border-radius: 99rem;
}
.active-order__items.main-block__box::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaaaa;
}
.active-order__block {
  padding: 10px;
}
.active-order__head {
  padding: 10px;
}
.active-order__img-block {
  width: 15%;
  height: 30px;
  max-width: 30px;
  border-radius: 5px;
  margin-right: 10px;
  background: #e0e9fa;
}
.active-order__img {
  width: 100%;
}
.active-order__head-text {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #707070;
  min-width: 80px;
}
.active-order__head-number {
  padding: 5px 6px 4px 5px;
  margin-left: 10px;
  height: auto;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
}
.active-order__head-select {
  margin: 0 20px 0 10px;
  min-width: 113px;
  height: 30px;
  font-size: 12px;
  line-height: 14px;
}
.active-order__head-icon {
  transform: rotate(270deg);
}
.active-order__box {
  /* width: 93%; */
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;

  gap: 0.5rem;
  flex-grow: 1;
  justify-content: flex-start;
}
.table-box {
  width: 25px;
  position: absolute;
  top: 1px;
  right: -5px;
}
.active-order__item {
  height: 50px;
  margin-top: 6px;
  padding: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
}
.active-order__item:hover {
  background-color: #f2f2f2;
}
.active-order__item:not(:last-child) .active-order__box {
  border-bottom: 1px solid #f2f2f8;
  padding: 10px 0;
}
.active-order__id {
  /* width: 32%; */
  /* flex-basis: 140px; */
  min-width: 110px;
  /* flex-grow: 1; */
  position: relative;
}
.active-order__info {
  /* width: 35%; */
  min-width: 250px;
  /* flex-basis: 400px; */
}
.active-order__status {
  margin-top: 6px;
  align-items: center;
}
.active-order__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 2px;
}
.active-order__text {
  font-size: 12px;
  line-height: 12px;
  color: #707070;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.active-order__text span:not(:last-child):after {
  content: ", ";
}
.active-order__data-text {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 14px;
  color: #222;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* Chrome */
  word-wrap: break-word; /* IE */

  flex-grow: 1;
  flex-shrink: 0;

  /* overflow: hidden; */
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */

  /* font-size: 0.9rem; */
}
.active-order__text_yellow {
  color: #f0af11;
}
.active-order__info-icon {
  margin: 2px 5px;
}
.active-order__date {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  margin: 0 0 0 5px;
}
.active-order__date-box {
  min-width: 200px;
  /* flex-basis: 26%; */

  /* text-align: right; */
}
.active-order__date-driver {
  justify-content: flex-start;
}
.active-order__date-transport {
  justify-content: flex-start;
}
.active-order__date-box > div {
  gap: 0.5rem;
  /* max-width: 150px; */
}
.active-order__data-name {
  font-size: 0.9em;
  font-weight: 500;
  color: #777;
}
.table-box img {
  width: 12px;
}
</style>
